<template>
  <div>
    <div class="grid place-items-center h-screen">
      <vs-card v-if="reference && !dataLoading" class="m-auto p-6 w-11/12 lg:w-5/12">
        <div v-if="reference">
          <img class="object-contain h-full w-full" src="../../assets/images/rag-logo-white.png" />
          <div v-if="!isVerified && !isReported && !wasReported && !wasVerified && !reportedBefore">
            <p class="mt-10 text-center text-xl"
              >You were added as a reference contact to <strong>{{ getUserName }}'s</strong> Recruit a Guide profile. Please see the details
              of the reference below and verify that they are correct.</p
            >

            <p class="text-center mt-10 text-xl"
              ><strong>Reference for {{ getUserName }}</strong></p
            >
            <div class="mt-8 flex flex-col">
              <div class="flex flex-col md:flex-row justify-between">
                <div class="text-xl"><strong>Reference name:</strong></div>
                <div class="text-xl">{{ reference.name }}</div>
              </div>
              <div class="flex flex-col md:flex-row justify-between">
                <div class="text-xl"><strong>Job title:</strong></div>
                <div class="text-xl">{{ reference.title }}</div>
              </div>
              <div class="flex flex-col md:flex-row justify-between">
                <div class="text-xl"><strong>Company:</strong></div>
                <div class="text-xl">{{ reference.company }}</div>
              </div>
              <div class="flex flex-col md:flex-row justify-between">
                <div class="text-xl"><strong>Contact number:</strong></div>
                <div class="text-xl">{{ reference.contactPhone }}</div>
              </div>
              <div class="flex flex-col md:flex-row justify-between">
                <div class="text-xl"><strong>Email:</strong></div>
                <div class="text-xl">{{ reference.contactEmail }}</div>
              </div>
              <div class="mt-8">
                <p class="text-lg mb-2">Please leave a comment (optional)</p>
                <vs-textarea v-model="reference.referenceComment" class="inputx" />
              </div>
              <div class="flex justify-items-center mt-10">
                <vs-button v-if="!submittingReference" class="m-auto px-12" @click="verifyReference">Verify</vs-button>
                <vs-button v-if="submittingReference" class="m-auto" disabled>Submitting...</vs-button>
              </div>
              <div>
                <p class="underline m-auto text-center mt-5"
                  ><span class="cursor-pointer" @click="toggleReportModal">Something wrong? Please report this reference</span></p
                >
              </div>
            </div>
          </div>
          <div v-if="wasVerified || wasReported || isVerified || isReported || reportedBefore">
            <p v-if="wasVerified" class="mt-10 text-center text-xl">Thank you for taking the time to verify this reference!</p>
            <p v-if="wasReported" class="mt-10 text-center text-xl">Your report has been submitted and will be reviewed.</p>
            <p v-if="isVerified" class="mt-10 text-center text-xl">This reference has already been verified.</p>
            <p v-if="isReported || reportedBefore" class="mt-10 text-center text-xl">This reference has already been reported.</p>
            <p class="text-center text-lg mt-2"><a href="https://www.recruitaguide.co.za">www.recruitaguide.co.za</a></p>
          </div>
        </div>
      </vs-card>
      <vs-card v-if="!reference && !dataLoading" class="m-auto p-6 w-5/12">
        <img class="object-contain h-full w-full" src="../../assets/images/rag-logo-white.png" />
        <div>
          <p class="mt-10 text-center text-xl">This reference has been removed.</p>

          <p class="text-center mt-10 text-xl"> </p
        ></div>
      </vs-card>
      <div v-if="reference">
        <vs-popup class-content="popup-example" title="Why are you reporting this reference?" :active.sync="showReportModal">
          <vs-textarea v-model="reference.reportComment" class="inputx" placeholder="Please provide a comment..." />
          <div class="flex justify-between">
            <vs-button v-if="!submittingReport" :disabled="isDisabled" color="danger" @click="reportReference">Report</vs-button>
            <vs-button v-if="submittingReport" disabled color="danger">Submitting...</vs-button>
            <vs-button :disabled="submittingReport ? true : false" color="grey" @click="toggleReportModal">Cancel</vs-button>
          </div>
        </vs-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInAnonymously } from 'firebase/auth';
import firebase from 'firebase/compat/app';
export default {
  components: {
    // LoadingSkeleton,
  },
  data() {
    return {
      wasVerified: false,
      wasReported: false,
      reportedBefore: null,
      isVerified: null,
      isReported: null,
      showReportModal: false,
      submittingReference: false,
      submittingReport: false,
      dataLoading: true,
      refId: '',
      userId: '',
      refUser: '',
      user: '',
      reference: {},
    };
  },
  computed: {
    isDisabled() {
      if (this.reference.reportComment === '' || this.reference.reportComment === null) return true;
      return false;
    },
    getUserName() {
      if (this.user.firstName && this.user.firstName !== '' && this.user.lastName && this.user.lastName !== '') {
        return `${this.user.firstName} ${this.user.lastName}`;
      } else {
        return this.user.fullName;
      }
    },
  },
  beforeDestroy() {
    getAuth().signOut();
  },
  created() {
    document.addEventListener('beforeunload', getAuth().signOut());
    fetch(process.env.VUE_APP_API + '/validateToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: this.$route.query.userId,
        // userId: '123',
        refId: this.$route.query.refId,
      }),
    })
      .then(async (response) => {
        const JSONresponse = await response.json();
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          signInAnonymously(auth).then(() => {
            this.refId = JSONresponse.refId;
            this.userId = JSONresponse.userId;
            this.getReference();
          });
        } else {
          this.refId = JSONresponse.refId;
          this.userId = JSONresponse.userId;
          this.getReference();
        }
      })
      .catch((e) => {
        console.log(e, 'error');
      });
  },

  methods: {
    async getReference() {
      this.$vs.loading({ type: 'corners' });
      try {
        this.refUser = await firebase.firestore().collection('users').doc(this.userId).get();
        this.user = this.refUser.data();
      } catch (error) {
        console.log(error);
      }

      this.$vs.loading.close();

      this.reference = this.user.references.find((ref) => {
        return ref.id === this.refId;
      });
      this.dataLoading = false;

      if (this.reference) {
        this.isVerified = this.reference.verified;
        this.reportedBefore = this.reference.reportedBefore;
        this.isReported = this.reference.reported;
      }

      if (this.isVerified || this.isReported || this.reportedBefore) {
        getAuth().signOut();
      }

      this.$vs.loading.close();
    },
    async verifyReference() {
      this.reference.verified = true;
      this.reference.reported = false;
      this.reference.actionedAt = new Date();
      this.submittingReference = true;
      // const auth = getAuth();
      // signInAnonymously(auth).then(async () => {
      try {
        const uRef = await firebase.firestore().collection('users').doc(this.userId);
        await uRef.update({ references: this.user.references });
      } catch (error) {
        console.log(error);
      }

      this.submittingReference = false;
      this.wasVerified = true;
      getAuth().signOut();
      // });
    },
    toggleReportModal() {
      this.showReportModal = !this.showReportModal;
      this.reference.reportComment = null;
    },
    async reportReference() {
      this.reference.referenceComment = null;
      this.reference.reported = true;
      this.reference.verified = false;
      this.reference.reportedUserName =
        this.user.firstName && this.user.lastName ? `${this.user.firstName} ${this.user.lastName}` : this.user.fullName;
      this.reference.actionedAt = new Date();
      this.submittingReport = true;

      // signInAnonymously(auth).then(async () => {
      try {
        const uRef = await firebase.firestore().collection('users').doc(this.userId);
        await uRef.update({ references: this.user.references });
        await this.$store.dispatch('referenceReportsModule/set', this.reference);
      } catch (error) {
        console.log(error);
      }

      this.showReportModal = !this.showReportModal;
      this.submittingReport = false;
      this.wasReported = true;
      getAuth().signOut();
      // });
    },
  },
};
</script>

<style lang="stylus">

.report-modal
  .vs-input
    float left
    width 50%
    margin 10px
    margin-top 5px
  .con-select
    margin-left 10px
    width 50%
    margin-bottom 10px
</style>
